import { render, staticRenderFns } from "./HotelMapCard.vue?vue&type=template&id=08fbe907&scoped=true"
import script from "./HotelMapCard.vue?vue&type=script&lang=js"
export * from "./HotelMapCard.vue?vue&type=script&lang=js"
import style0 from "./HotelMapCard.vue?vue&type=style&index=0&id=08fbe907&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fbe907",
  null
  
)

export default component.exports