const POSITION = {
  BOTTOM: 11,
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT: 7,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP: 2,
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3,
}

export const mapOptions = {
  zoomControlOptions: {
    position: POSITION.LEFT_TOP,
  },
  streetViewControlOptions: {
    position: POSITION.LEFT_TOP,
  },
  mapTypeControlOptions: {
    position: POSITION.TOP_CENTER,
  },
  fullscreenControl: false,
  styles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels',
    },
    {
      featureType: 'poi',
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
    },
    // {
    //   featureType: 'road.arterial',
    //   elementType: 'labels',
    // },
    // {
    //   featureType: 'road.highway',
    //   elementType: 'labels',
    // },
    // {
    //   featureType: 'road.local',
    // },
    // {
    //   featureType: 'road.local',
    //   elementType: 'labels',
    // },
    {
      featureType: 'transit',
    },
  ].map(style => ({
    ...style,
    stylers: style.stylers || [{ visibility: 'off' }],
  })),
}
