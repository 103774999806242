<template>
  <div
    style="display: grid; grid-template-rows: 130px 1fr; max-width: 90vw"
    class="hotel-card app-card swiper-slide"
    @click="$emit('click')"
  >
    <div class="hotel-card-top">
      <img v-if="hotel.mediaList[0]" :src="hotel.mediaList[0]" alt="Hotel" />
      <IconPlaceholderHotelHorizontal v-else style="color: #e5a038" />
    </div>
    <div class="hotel-card-bot">
      <div class="hotel-card-bot-left">
        <div class="fs-13" style="font-family: LuciferSans">
          {{ hotel.name }}
        </div>
        <div class="fs-11" style="font-family: DDin; text-transform: uppercase">
          {{ hotel.address }}
        </div>
      </div>

      <div class="hotel-card-bot-right">
        <div class="fs-16">{{ price }}</div>
        <p class="hotel-rating">
          {{ $t('hotel.rating-text').replace('%rating', hotel.rating) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlaceholderHotelHorizontal from '../../icons/placeholders/IconPlaceholderHotelHorizontal.vue'
export default {
  name: 'HotelMapCard',
  props: {
    hotel: Object,
    isSelected: Boolean,
  },
  computed: {
    price() {
      const priceObj = this.hotel.rooms[0]?.price
      let price = parseInt(priceObj?.total)
      return price.toLocaleString(undefined, {
        style: 'currency',
        currency: priceObj?.currency || 'USD',
        minimumFractionDigits: 0,
      })
    },
  },
  components: { IconPlaceholderHotelHorizontal },
}
</script>

<style scoped lang="scss">
.hotel-card {
  min-height: 210px;
  height: fit-content;
  width: 380px;
  cursor: pointer;
}
.hotel-card-top {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: var(--color-secondary);
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
  }
}
.hotel-card-bot {
  min-height: 85px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--color-light);
  border-radius: 8px;
}
.hotel-card-bot-right {
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: flex-end;
}
.hotel-card-bot-left {
  grid-auto-rows: 1fr;
  justify-items: flex-end;
}
.hotel-rating {
  font-family: DDin;
  text-align: end;
  color: var(--color-primary);
}
</style>
