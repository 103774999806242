<template>
  <div class="trip-hotels-map-wrapper">
    <AppNavButton
      icon="close"
      class="trip-hotels-map-close-button"
      to="/search/hotels"
    />
    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="roadmap"
      class="trip-hotels-map"
      :options="mapOptions"
    >
      <GmapMarker
        v-for="hotel in hotels"
        :key="hotel.id"
        :position="hotel.position"
        :clickable="true"
        :draggable="false"
        :z-index="hotel.id === activeHotel.id ? 2 : 1"
        :icon="
          hotel.id === activeHotel.id
            ? require('@/assets/marker-red.png')
            : require('@/assets/marker.png')
        "
        @click="onPinClick(hotel)"
      />
    </GmapMap>
    <Swiper
      :active-index="activeHotelIndex"
      :update-key="activeHotelIndex"
      class="trip-hotels-map-carousel"
      @confirm="$router.push('/search/hotel/details')"
      @select="onSelect"
    >
      <HotelMapCard
        v-for="hotel in hotels"
        :key="hotel.id"
        :hotel="hotel"
        :data-selected="hotel.id === activeHotel.id"
      />
    </Swiper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HotelMapCard from '../../../components-rf/search/hotels/HotelMapCard.vue'
import { mapOptions } from './TripHotelsMapOptions.js'
import Swiper from '../../../components/utils/Swiper.vue'
import AppNavButton from '../../../components-rf/AppNavButton.vue'

export default {
  name: 'SearchHotelMap',
  components: { Swiper, HotelMapCard, AppNavButton },
  created() {
    this.processSelection({ hotel: this.getActive.hotel })
  },
  data() {
    return {
      map: null,
      center: null,
      mapOptions,
      activeHotel: undefined,
      activeHotelIndex: undefined,
    }
  },
  methods: {
    ...mapActions('SearchState', ['select']),
    processSelection({ hotel, index }) {
      const _index = index ?? this.hotels.findIndex(({ id }) => id === hotel.id)
      const _hotel = this.hotels[_index]

      this.activeHotel = _hotel
      this.center = _hotel.position
      this.activeHotelIndex = _index
      return { hotel: _hotel, index: _index }
    },
    onPinClick(hotel) {
      const data = this.processSelection({ hotel })
      this.select({ hotelId: data.hotel.id })
    },
    onSelect(index) {
      const { hotel } = this.processSelection({ index })
      this.select({ hotelId: hotel.id })
    },
  },
  computed: {
    ...mapGetters('SearchState', ['getActive', 'getSelected']),
    selection: vm => vm.getSelected({}),
    hotels: vm =>
      vm.getActive.selectableHotels.map(hotel => ({
        ...hotel,
        position: {
          lat: parseFloat(hotel.coordinates.latitude),
          lng: parseFloat(hotel.coordinates.longitude),
        },
      })),
  },
}
</script>

<style scoped lang="scss">
.trip-hotels-map-wrapper {
  position: relative;
  height: 100%;
}
.trip-hotels-map {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.trip-hotels-map-close-button {
  position: absolute;
  z-index: 100;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 9px;
}
.trip-hotels-map-carousel {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
